import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Grid from '@material-ui/core/Grid';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import {TablePagination} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import xlsx from 'xlsx'
import {TextField, MenuItem} from '@material-ui/core'
import Axios from 'axios'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    height: '700px'
  },
});

const sim_header = [
    { text: 'Sim Transaction', value: 'transaction', defaultShow: false },
    { text: 'Date and Time', value: 'date', defaultShow: false },
    { text: 'Added By', value: 'added_by', defaultShow: false },
    { text: 'Vendor', value: 'vendor', defaultShow: false },
    { text: 'Operator', value: 'operator', defaultShow: true },
    { text: 'Sim Type', value: 'sim_type', defaultShow: true },
    { text: 'Sim Mobile No.', value: 'sim_mobile_no', defaultShow: true },
    { text: 'Sim Serial No.', value: 'sim_serial_no', defaultShow: true },
    { text: 'Sim Code', value: 'sim_code', defaultShow: false },
    { text: 'Sim Status on Portal', value: 'sim_status', defaultShow: true },
    { text: 'Inventory', value: 'inventory', defaultShow: true },
    { text: 'Client', deviceData: true, value: 'client', defaultShow: true },
    { text: 'Hardware Vendor', deviceData: true, value: 'vendor', defaultShow: true },
    { text: 'Hardware Model', deviceData: true, value: 'hardware_model', defaultShow: true },
    { text: 'Hardware No.', deviceData: true, value: 'hardware_no', defaultShow: true },
    { text: 'Hardware Sl.No', deviceData: true, value: 'hardware_serial_no', defaultShow: true },
    { text: 'Hardware IMEI', deviceData: true, value: 'hardware_imei', defaultShow: true },
    { text: 'Hardware Sim Status', deviceData: true, value: 'sim_status', defaultShow: true },
    { text: 'Hardware Sim Position', deviceData: true, value: 'sim_position', defaultShow: true },
    { text: 'Hardware Sim Remarks', deviceData: true, value: 'sim_remarks', defaultShow: true },
]

export default function SimList(props) {

  const classes = useStyles();
  const [state, setState] = React.useState({
      rowsPerPage: 10,
      page: 0,
      matchedSimcards: [],
      simcards: [],
      operators: [],
      sim_types: [],
      sim_status: [],
      selectedSim: {},
      table: {
        actual_headers: {},
        selectedOperator: '',
        selectedSimType: '',
        selectedSimStatus: ''
      },
      selectedSims: {}
  })

  const [reload, reloadNow] = React.useState(true);

  const handleChangePage = (event, newPage) => {
    setState(state => ({
      ...state,
      page: newPage
    }))
  };


  const [open, setOpen] = React.useState(false);
  const fetchId = useRef();

  const handleClickOpen = () => {
      setOpen(true);
  };

  const handleClose = () => {
      setOpen(false);
  };

  const clearAllHeaders = () => {
    let headers = {...state.table.actual_headers}
    Object.keys(headers).forEach(key => {
        headers[key] = false
    })
    setState(state => ({
        ...state,
        table: {
            ...state.table,
            actual_headers: headers
        }
    }))
  }

  const selectAllHeaders = () => {
    let headers = {...state.table.actual_headers}
    Object.keys(headers).forEach(key => {
        headers[key] = true
    })
    setState(state => ({
        ...state,
        table: {
            ...state.table,
            actual_headers: headers
        }
    }))
  }

  const handleChangeRowsPerPage = event => {
    let value = +event.target.value
    setState(state => ({
      ...state,
      page: 0,
      rowsPerPage: value
    }))
  };

  React.useEffect(() => {
    let headers = {};
    headers['S No.'] = true;
    headers['Actions'] = true;
    sim_header.forEach(header => {
        headers[header.text] = header.defaultShow;
    })
    setState(state => ({
        ...state,
        table: {
            ...state.table,
            actual_headers: headers
        }
    }))
    fetchSimcardFilters()
  }, [])

  const changeHandler = (e, key, prop) => {
    let value = e.target.value;
    if (key) {
        setState(state => ({
            ...state,
            [key]: {
                ...state[key],
                [prop]: value
            }
        }))
    } else {
        setState(state => ({
            ...state,
            [prop]: value
        }))
    }
  }

  React.useEffect(() => {
      if (fetchId) {
          console.log("clearing timeout", fetchId);
          clearTimeout(fetchId.current);
      }
    fetchId.current = setTimeout(() => {
        let filters = {};
        if (state.table.selectedOperator) {
            filters.operator = state.table.selectedOperator
        }
        if (state.table.selectedSimType) {
            filters.sim_type = state.table.selectedSimType
        }
        if (state.table.selectedSimStatus) {
            filters.sim_status = state.table.selectedSimStatus
        }
        if (props.search) {
            filters.search = props.search;
        }
       let url = 'https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/simcards';
        // let url = 'http://localhost:8000/simcards'
        axios.post(`${url}?limit=${state.rowsPerPage}&skip=${state.rowsPerPage * state.page}`, filters)
        .then(
            (res) => {
                const resp = JSON.parse(res.data);
                let dev = resp.devices;
                let sim = resp.simcards;
                let op = [];
                let simtypes = [];
                let sim_status = [];
                let map = {};
                dev.forEach(d => {
                    if(d.sim_mobile_no)
                    map[d.sim_mobile_no] = d;
                })
                sim = sim.map(s => {
                    const sReplica = {...s};
                    sReplica.inDevice = [map[s.sim_mobile_no]];
                    op.push(s.operator);
                    simtypes.push(s.sim_type);
                    sim_status.push(s.sim_status);
                    return sReplica;
                })
                setState(state => ({
                    ...state,
                    simcards: sim,
                    matchedSimcards: sim,
                    // operators: [...new Set(op)],
                    // sim_types: [...new Set(simtypes)],
                    // sim_status: [...new Set(sim_status)],
                    totalSimCards: resp.count
                }));
            }
        ).catch(
            err => {
                alert('Error Occured. See console.')
                console.log(err);
            }
        )
      }, 600)
  }, [
      reload, 
      props.reload, 
      state.table.selectedOperator, 
      state.table.selectedSimType, 
      state.table.selectedSimStatus, 
      state.rowsPerPage, 
      state.page,
      props.search
    ]);
    const fetchSimcardFilters = () => {
       Axios.get('https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/simcards/filters').then(
            (res) => {
                console.log(res.data);
                if(res.data?.status && res.data?.result){
                    const filter_data =  res.data?.result
                    setState(state => ({
                        ...state,
                        operators: [...(filter_data?.operator ?? [])],
                        sim_types: [...(filter_data?.sim_type ?? [])],
                        sim_status: [...(filter_data?.sim_status ?? [])]
                    }));
                }
                // alert(res.data.status);
                // reloadNow(v => !v);
            }
        ).catch(
            err => {
            alert('Error Occured, check console for more');
            }
        );
      }
  const download = () => {
    let download_data = [...state.matchedSimcards];
    if (Object.keys(state.selectedSims).length > 0) {
        download_data = Object.keys(state.selectedSims).map(key => state.selectedSims[key])
    }
    const data = download_data
    .filter(obj => Object.keys(obj).some(val => (obj[val] + '').toLowerCase().includes(props.search.toLowerCase())))
    .filter(obj => (obj.operator === state.table.selectedOperator || state.table.selectedOperator === ''))
    .filter(obj => (obj.sim_type === state.table.selectedSimType || state.table.selectedSimType === ''))
    .filter(obj => (obj.sim_status === state.table.selectedSimStatus || state.table.selectedSimStatus === ''))
    .map(row => {
        let obj = {};
        sim_header.forEach(head => {
            obj[head.text] = row[head.value]
        })
        obj["_id"] = row["_id"];
        return obj
    })
    const wb = xlsx.utils.book_new();
    const file_name = `sims.xlsx`;
    const sheet_name = "Data"
    xlsx.utils.book_append_sheet(
        wb, xlsx.utils.json_to_sheet(data),
        sheet_name
    );
    xlsx.writeFile(wb, file_name);
}
  const edit = () => {
    let resp = window.confirm("Are you sure?");
        if (resp) {
            let url = 'https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/edit-sim';
            Axios.post(url, JSON.stringify(state.selectedSim)).then(
                (res) => {
                console.log(res);
                if (res.status === 200) {
                    alert('device edited');
                    reloadNow(v => !v);
                }
                }
            ).catch(
                err => {
                alert('Error Occured, check console for more');
                }
            );
        }
  }

  const deleteDevice = (e, device) => {
    e.stopPropagation();
    let resp = window.confirm("Are you sure?");
    if (resp) {
        let url = 'https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/delete-sim';
        Axios.post(url, JSON.stringify({
            _id: device._id
        })).then(
            (res) => {
            console.log(res);
            if (res.status === 200) {
                alert('device deleted');
                reloadNow(v => !v);
            }
            }
        ).catch(
            err => {
            alert('Error Occured, check console for more');
            }
        );
    }
  }

  const deleteDevices = (device) => {
    let ids = Object.keys(state.selectedSims)
    Axios.post('https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/delete-simcards', JSON.stringify(ids)).then(
        (res) => {
            console.log(res);
            alert(res.data.status);
            reloadNow(v => !v);
        }
    ).catch(
        err => {
        alert('Error Occured, check console for more');
        }
    );
  }

  const toggleSim = (sim) => {
      let selected = {...state.selectedSims};
      if (selected[sim._id]) {
          delete selected[sim._id];
          console.log("delete", selected);
      } else {
          selected[sim._id] = sim;
          console.log("add", selected);
      }
      setState(state => ({
          ...state,
          selectedSims: selected
      }))
  }

  const selectAll = () => {
    let selected = {};
    state.simcards.forEach(s => selected[s._id] = s);
    setState(state => ({
        ...state,
        selectedSims: selected
    }))
  }

  const deselectAll = () => {
    setState(state => ({
        ...state,
        selectedSims: {}
    }))
  }

  return (
    <Grid container style={{padding: '20px'}}>
      <Grid item xs={12}>
        <Grid container justify="space-between">
          <Grid item>
              <Button onClick={handleClickOpen} variant="outlined">Set Display Items</Button>
              <Button onClick={download} variant="outlined">Download</Button>
          </Grid>
          <Grid item>
            <Button onClick={selectAll} variant="outlined">Select All</Button>
            <Button onClick={deselectAll} variant="outlined">Deselect All</Button>
              <TextField style={{ width: '150px'}} variant="outlined" select label="Operator" value={state.table.selectedOperator} onChange={e => changeHandler(e, 'table', 'selectedOperator')}>
                  <MenuItem value={''}>All</MenuItem>
                  {(state.operators || []).map(operator => (<MenuItem key={operator} value={operator}>{operator}</MenuItem>))}
              </TextField>
              <TextField style={{ width: '150px'}} variant="outlined" select label="Sim Type" value={state.table.selectedSimType} onChange={e => changeHandler(e, 'table', 'selectedSimType')}>
                  <MenuItem value={''}>All</MenuItem>
                  {(state.sim_types || []).map(type => (<MenuItem key={type} value={type}>{type}</MenuItem>))}
              </TextField>
              <TextField style={{ width: '150px'}} variant="outlined" select label="Sim Status" value={state.table.selectedSimStatus} onChange={e => changeHandler(e, 'table', 'selectedSimStatus')}>
                  <MenuItem value={''}>All</MenuItem>
                  {(state.sim_status || []).map(status => (<MenuItem key={status} value={status}>{status}</MenuItem>))}
              </TextField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
            <TableHead>
            <TableRow>
              {state.table.actual_headers['S No.'] ? <TableCell align="center">S.No.</TableCell> : null}
              {sim_header.map(header => state.table.actual_headers[header.text] ? (<TableCell key={header.text} align="center">{header.text}</TableCell>) : null)}
              {state.table.actual_headers['Actions'] ? <TableCell align="center">Actions</TableCell> : null}
            </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(state.matchedSimcards) ? [...state.matchedSimcards]
            //   .filter(obj => Object.keys(obj).some(val => (obj[val] + '').toLowerCase().includes(props.search.toLowerCase())))
            //   .filter(obj => (obj.operator === state.table.selectedOperator || state.table.selectedOperator === ''))
            //   .filter(obj => (obj.sim_type === state.table.selectedSimType || state.table.selectedSimType === ''))
            //   .filter(obj => (obj.sim_status === state.table.selectedSimStatus || state.table.selectedSimStatus === ''))
            //   .splice(state.rowsPerPage * state.page, state.rowsPerPage)
              .map((device, index) => {
                  return (<TableRow hover key={index}>
                    {state.table.actual_headers['S No.'] ? <TableCell align="center">{index+1}</TableCell>: null}
                    {sim_header.map(header => state.table.actual_headers[header.text] ? 
                        (<TableCell key={header.text} onClick={() => toggleSim(device)} style={{
                            backgroundColor: state.selectedSims[device._id] ? '#3F51B5' : 'inherit',
                            color: state.selectedSims[device._id] ? 'white' : 'inherit',
                        }} align="center">
                            {
                                header.deviceData ? ((device.inDevice || [])[0] || {})[header.value] : device[header.value]
                            }
                        </TableCell>) 
                        : null)}
                    {state.table.actual_headers['Actions'] ? <TableCell align="center">
                    <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                          <Tooltip title="Edit this sim">
                              <EditIcon data-toggle="modal" data-target="#EditSim" 
                              onClick={(e) => {
                                  e.stopPropagation();
                                  changeHandler({target:{value:device}}, false, 'selectedSim')
                              }}
                              />
                          </Tooltip>
                          {/* <Tooltip title="Delete this sim">
                              <DeleteIcon data-toggle="modal" 
                              onClick={(e) => deleteDevice(e, device)}
                              />
                          </Tooltip> */}
                                <Tooltip title="Delete selected devices" onClick={(e) => {
                                        e.stopPropagation();
                                        deleteDevices(device);
                                    }}>
                                    <DeleteSweepIcon />
                                </Tooltip>
                          </ButtonGroup>
                    </TableCell> : null}
                  </TableRow>)
              }) : null}
            </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={state.totalSimCards}
            // .filter(obj => Object.keys(obj).some(val => (obj[val] + '').toLowerCase().includes(props.search.toLowerCase())))
            // .filter(obj => (obj.operator === state.table.selectedOperator || state.table.selectedOperator === ''))
            // .filter(obj => (obj.sim_type === state.table.selectedSimType || state.table.selectedSimType === ''))
            // .filter(obj => (obj.sim_status === state.table.selectedSimStatus || state.table.selectedSimStatus === '')).length}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      </Grid>
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">{"Set Display Item"}</DialogTitle>
          <DialogContent>
          <Grid container>
          {Object.keys(state.table.actual_headers).map(key => (
          <Grid item xs={6} key={key}>
              <FormControlLabel
              control={
                  <Checkbox
                  checked={state.table.actual_headers[key]}
                  onChange={() => {
                      setState(state => ({
                          ...state,
                          table: {
                              ...state.table,
                              actual_headers: {
                                  ...state.table.actual_headers,
                                  [key]: !state.table.actual_headers[key]
                              }
                          }
                      }))
                  }}
                  name="checkedB"
                  color="primary"
                  />
              }
              label={key}
              />
          </Grid>
          ))}            
          </Grid>
          </DialogContent>
          <DialogActions>
          <Button onClick={clearAllHeaders} color="primary" autoFocus>
              Clear All
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
              Ok
          </Button>
          <Button onClick={selectAllHeaders} color="primary" autoFocus>
              Select All
          </Button>
          </DialogActions>
      </Dialog>
      <div className="modal fade" id="EditSim" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
              <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Edit Sim</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div className="modal-body">

            <Grid container justify="space-evenly">
                <Grid item xs={4}>
                    <TextField disableEnforceFocus style={{marginBottom:'10px', width: '201px'}} fullWidth label="Transaction" value={state.selectedSim.transaction || ''} onChange={(e) => changeHandler(e, 'selectedSim', 'transaction')} select>
                        <MenuItem value={''}>
                            Blank
                        </MenuItem>
                        {[...new Set(['Received from Vendor', 'Assigned to Device', state.selectedSim.transaction])].map(option => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField style={{marginBottom:'10px'}} label="Date" value={state.selectedSim.date} onChange={(e) => changeHandler(e, 'selectedSim', 'date')}/>
                </Grid>
                <Grid item xs={4}>
                    <TextField style={{marginBottom:'10px'}} label="Added By" value={state.selectedSim.added_by} onChange={(e) => changeHandler(e, 'selectedSim', 'added_by')}/>
                </Grid>
                <Grid item xs={4}>
                    <TextField style={{marginBottom:'10px'}} label="Vendor" value={state.selectedSim.vendor} onChange={(e) => changeHandler(e, 'selectedSim', 'vendor')}/>
                </Grid>
                <Grid item xs={4}>
                    <TextField style={{marginBottom:'10px', width: '201px'}} label="Operator" value={state.selectedSim.operator || ''} onChange={(e) => changeHandler(e, 'selectedSim', 'operator')} select>
                        <MenuItem value={''}>
                            Blank
                        </MenuItem>
                        {[...new Set(['Airtel', 'Vodafone', 'Idea', state.selectedSim.operator])].map(option => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField style={{marginBottom:'10px', width: '201px'}} label="Sim Type" value={state.selectedSim.sim_type || ''} onChange={(e) => changeHandler(e, 'selectedSim', 'sim_type')} select>
                        <MenuItem value={''}>
                            Blank
                        </MenuItem>
                        {[...new Set(['Airtel 10 m2m', 'Airtel 13 m2m', 'Airtel Prepaid', 'Voda 10 m2m', 'Voda 13 m2m', 'Voda Prepaid', 'Idea 13 m2m', 'Idea 10 m2m', 'Idea Prepaid', state.selectedSim.sim_type])].map(option => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField style={{marginBottom:'10px'}} label="Sim Mobile No." value={state.selectedSim.sim_mobile_no} onChange={(e) => changeHandler(e, 'selectedSim', 'sim_mobile_no')}/>
                </Grid>
                <Grid item xs={4}>
                    <TextField style={{marginBottom:'10px'}} label="Sim Serial No." value={state.selectedSim.sim_serial_no} onChange={(e) => changeHandler(e, 'selectedSim', 'sim_serial_no')}/>
                </Grid>
                <Grid item xs={4}>
                    <TextField style={{marginBottom:'10px'}} label="Sim Code" value={state.selectedSim.sim_code} onChange={(e) => changeHandler(e, 'selectedSim', 'sim_code')}/>
                </Grid>
                <Grid item xs={4}>
                    <TextField style={{marginBottom:'10px', width: '201px'}} label="Sim Status On Portal" value={state.selectedSim.sim_status || ''} onChange={(e) => changeHandler(e, 'selectedSim', 'sim_status')} select>
                        <MenuItem value={''}>
                            Blank
                        </MenuItem>
                        {[...new Set(['Active', 'Inactive', 'Lost', state.selectedSim.sim_status])].map(option => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField style={{marginBottom:'10px', width: '201px'}} label="Inventory" value={state.selectedSim.inventory || ''} onChange={(e) => changeHandler(e, 'selectedSim', 'inventory')} select>
                        <MenuItem value={''}>
                            Blank
                        </MenuItem>
                        {[...new Set(['On Field', 'In Office - Ops', 'In Office - Hardware', state.selectedSim.inventory])].map(option => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>
              </div>
              <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                  <button type="button" className="btn btn-primary" onClick={edit}>Edit</button>
              </div>
              </div>
          </div>
      </div>
    </Grid>
  );
}