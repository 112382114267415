import React, { useMemo } from "react";
import "./App.css";
import Navbar from "./components/navbar";
import DeviceList from "./components/DeviceList/DeviceList";
import SimList from "./components/Simlist";
import History from "./components/history";
import { Switch, Route } from "react-router-dom";
import ReactGA from "react-ga";
import Container from "./components/Counters/Container/Container";
import { debounce } from "@material-ui/core";

function App() {
  // React.useEffect(state => {
  //   Axios.get('https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/extra/client').then(
  //       (res1) => {
  //           Axios.get('https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/extra/make').then(
  //               (res2) => {
  //                   setClients(clients => JSON.parse(res1.data));
  //                   setMakes(makes => JSON.parse(res2.data));
  //               }

  //           ).catch(
  //               err => {
  //                   alert('Error Occured.')
  //                   console.log(err);
  //               }
  //           )
  //       }
  //   ).catch(
  //       err => {
  //           alert('Error Occured.')
  //           console.log(err);
  //       }
  //   )
  // }, [])

  const [search, setSearch] = React.useState("");
  const [client, setClient] = React.useState({})

  const onSearch = useMemo(
    () =>
      debounce((e) => {
        setSearch(e);
      }, 1000),
    []
  );



  const onStats = (data) => {
    console.log("inside app.js stats data", data)
  };

  React.useEffect(() => {
    ReactGA.initialize("UA-168562633-1");
  }, []);

  React.useEffect(()=>{
    // console.log("client data updated",client)
  },[client])

  const [reload, reloadNow] = React.useState(true);

  return (
    <div className="App">
      <Navbar
        onSearch={(e) => {
          const value = e.target.value;
          onSearch(value);
        }}
        reloadNow={reloadNow}
      />
      <Container onStats={(data)=> onStats(data)} setClient={(data)=> setClient(data)} />
      <Switch>
        <Route
          path="/"
          exact
          render={(props) => (
            <DeviceList {...props} reload={reload} search={search} clientList={client}/>
          )}
        ></Route>
        <Route
          path="/sim"
          exact
          render={(props) => (
            <SimList {...props} reload={reload} search={search} />
          )}
        ></Route>
        <Route
          path="/history"
          render={(props) => (
            <History {...props} reload={reload} search={search} />
          )}
        ></Route>
      </Switch>
    </div>
  );
}

export default App;
