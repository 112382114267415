import React from 'react'
import './table.css'
import GetAppIcon from '@material-ui/icons/GetApp';
import xlsx from 'xlsx'


function Table(props) {
    var downloadArr=[];
    const downloadSheet=(title)=>{
      if(title==="Clients"){
        xlsx.utils.book_append_sheet(props.workbook, props.worksheet,title);
        xlsx.writeFile(props.workbook, title+'.xlsx');
      }else{
      Object.keys(props.obj).map(function(key) {
        const temp={
          'item':key,
          'quantity':props.obj[key]
        }
        downloadArr.push(temp)
      })
      
      
        var worksheet = xlsx.utils.json_to_sheet(downloadArr);
        var new_workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(new_workbook, worksheet,title);
        xlsx.writeFile(new_workbook, title+'.xlsx');
       
    }

  }
 
    return (
        <div>
        <table className="tableC">
      <thead className="theadC">
        <tr className="trC">
          <th className="thC">{props.title}</th>
          <th className="thC"><GetAppIcon className="button" onClick={()=>downloadSheet(props.title)}/></th>
        </tr>
      </thead>

      <tbody className="tbodyC">
      {Object.keys(props.obj).map(function(key) {
        return <tr className="trC" key={key}><td className="tdC">{key}</td><td className="tdC">{props.obj[key]}</td></tr>
      })}
      </tbody>      
    </table>
        </div>
    )
}

export default Table
