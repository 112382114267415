import { useEffect, useState } from "react";

const usePagination = ({ initialPage = 1, initialPageSize = 10, onChange }) => {
  const [pagination, setPagination] = useState({
    page: initialPage,
    pageSize: initialPageSize,
  });

  useEffect(() => {
    if (initialPage !== pagination.page)
      setPagination({ ...pagination, page: initialPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPage]);

  useEffect(() => {
    if (initialPage !== pagination.pageSize)
      setPagination({ ...pagination, pageSize: initialPageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPageSize]);

  useEffect(() => {
    onChange && onChange(pagination.page, pagination.pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.page, pagination.pageSize]);

  const handlePageChange = (page) => setPagination({ ...pagination, page });
  const handlePageSizeChange = (pageSize) =>
    setPagination({ ...pagination, page: 1, pageSize });

  return {
    page: pagination.page,
    pageSize: pagination.pageSize,
    handlePageChange,
    handlePageSizeChange,
  };
};

export default usePagination;
