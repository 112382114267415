import React from "react";
import "./container.css";
import Table from "../Table/Table";
import Axios from "axios";
import xlsx from "xlsx";

function Container(props) {
  const [Client, setClient] = React.useState({});
  const [Model, setModel] = React.useState({});
  const [Position, setPosition] = React.useState({});
  const [Type, setType] = React.useState({});
  const [hardwareType, setHardwareType] = React.useState({});

  const sortedModel = {};
  const sortedClient = {};
  const sortedType = {};

  React.useEffect(() => {
    Axios.get(
      "https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/stats"
    ).then((response) => {
      setClient(response.data.Client);
      setModel(response.data["Hardware Model"]);
      setPosition(response.data["Hardware Position"]);
      setType(response.data["Sim Type"]);
      props.setClient(Object.keys(response.data.Client))
      // console.log("Client",response.data.Client)
    });
    Axios.get(
      "https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/clientWiseHardwareType"
    ).then((response) => {
      setHardwareType(response.data);
    });
    
  }, []);

  var downloadArr = [];

  Object.keys(hardwareType).map(function (key) {
    let temp = {};
    Object.keys(hardwareType[key]).map(function (key2) {
      temp = {
        Client: key,
        "Use and Throw": hardwareType[key]["Use and Throw"]
          ? hardwareType[key]["Use and Throw"]
          : 0,
        "GSM Device": hardwareType[key]["GSM Device"]
          ? hardwareType[key]["GSM Device"]
          : 0,
        "GPS Device": hardwareType[key]["GPS Device"]
          ? hardwareType[key]["GPS Device"]
          : 0,
        "GPS Lock": hardwareType[key]["GPS Lock"]
          ? hardwareType[key]["GPS Lock"]
          : 0,
        //
        "Device 6k": hardwareType[key]["Device 6k"]
          ? hardwareType[key]["Device 6k"]
          : 0,
        "Device 10k": hardwareType[key]["Device106k"]
          ? hardwareType[key]["Device 10k"]
          : 0,
        Other: hardwareType[key]["Other"] ? hardwareType[key]["Other"] : 0,
        OBD: hardwareType[key]["OBD"] ? hardwareType[key]["OBD"] : 0,
        Total:
          (hardwareType[key]["Use and Throw"]
            ? hardwareType[key]["Use and Throw"]
            : 0) +
          (hardwareType[key]["GSM Device"]
            ? hardwareType[key]["GSM Device"]
            : 0) +
          (hardwareType[key]["GPS Device"]
            ? hardwareType[key]["GPS Device"]
            : 0) +
          (hardwareType[key]["GPS Lock"] ? hardwareType[key]["GPS Lock"] : 0) +
          (hardwareType[key]["GPS Device 6k"]
            ? hardwareType[key]["GPS Device 6k"]
            : 0) +
          (hardwareType[key]["GPS Device 10k"]
            ? hardwareType[key]["GPS Device 10k"]
            : 0) +
          (hardwareType[key]["Other"] ? hardwareType[key]["Other"] : 0) +
          (hardwareType[key]["OBD"] ? hardwareType[key]["OBD"] : 0),
      };
    });
    downloadArr.push(temp);
  });
  downloadArr.sort((a, b) => {
    return b.Total - a.Total;
  });

  var total = {
    Client: "Total",
    "Use and Throw": 0,
    "GSM Device": 0,
    "GPS Device": 0,
    "GPS Lock": 0,
    "GPS Device 6k": 0,
    "GPS Device 10k": 0,
    Other: 0,
    OBD: 0,
    Total: 0,
  };

  Object.keys(downloadArr).map((key) => {
    total = {
      Client: "Total",
      "Use and Throw":
        total["Use and Throw"] + downloadArr[key]["Use and Throw"],
      "GSM Device": total["GSM Device"] + downloadArr[key]["GSM Device"],
      "GPS Device": total["GPS Device"] + downloadArr[key]["GPS Device"],
      "GPS Lock": total["GPS Lock"] + downloadArr[key]["GPS Lock"],
      Other: total["Other"] + downloadArr[key]["Other"],
      OBD: total["OBD"] + downloadArr[key]["OBD"],
      "GPS Device 6k":
        total["GPS Device 6k"] + downloadArr[key]["GPS Device 6k"],
      "GPS Device 10k":
        total["GPS Device 10k"] + downloadArr[key]["GPS Device 10k"],
      Total: total["Total"] + downloadArr[key]["Total"],
    };
  });

  downloadArr.push(total);
  //console.log(downloadArr)
  var worksheet = xlsx.utils.json_to_sheet(downloadArr);
  var new_workbook = xlsx.utils.book_new();

  var keysSortedModel = Object.keys(Model).sort(function (a, b) {
    return Model[b] - Model[a];
  });
  var keysSortedClient = Object.keys(Client).sort(function (a, b) {
    return Client[b] - Client[a];
  });
  var keysSortedType = Object.keys(Type).sort(function (a, b) {
    return Type[b] - Type[a];
  });
  // console.log(keysSorted)

  for (let i = 0; i < keysSortedModel.length; i++) {
    sortedModel[keysSortedModel[i]] = Model[keysSortedModel[i]];
  }

  for (let i = 0; i < keysSortedClient.length; i++) {
    sortedClient[keysSortedClient[i]] = Client[keysSortedClient[i]];
  }

  for (let i = 0; i < keysSortedType.length; i++) {
    sortedType[keysSortedType[i]] = Type[keysSortedType[i]];
  }

  // console.log(sortedClient)
  return (
    <div class="container">
      <Table
        title="Clients"
        obj={sortedClient}
        workbook={new_workbook}
        worksheet={worksheet}
      />
      <Table title="Hardware Model" obj={sortedModel} />
      <Table title="Hardware Position" obj={Position} />
      <Table title="SIM Type" obj={sortedType} />
    </div>
  );
}

export default Container;
