import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Grid from '@material-ui/core/Grid';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import {TablePagination, Tooltip} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {TextField, MenuItem} from '@material-ui/core'
import Axios from 'axios'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const history_header = [
    { text: 'Transaction', value: 'transaction' },
    { text: 'Date and Time', value: 'date' },
    { text: 'Added by', value: 'added_by' },
    { text: 'Vendor', value: 'vendor' },
    { text: 'Operator', value: 'operator' },
    { text: 'Sim Type', value: 'sim_type' },
    { text: 'Sim Mobile No.', value: 'sim_mobile_no' },
    { text: 'Sim Serial No.', value: 'sim_serial_no' },
    { text: 'Sim Code', value: 'sim_code' },
    { text: 'Sim Status on Portal', value: 'sim_status' },
    { text: 'Inventory', value: 'inventory' },
    { text: 'Client', value: 'client' },
    { text: 'City', value: 'city' },
    { text: 'Hardware Type', value: 'hardware_type' },
    { text: 'Hardware Model', value: 'hardware_model' },
    { text: 'Parsed By', value: 'parsed_by' },
    { text: 'Hardware No.', value: 'hardware_no' },
    { text: 'Hardware Serial No.', value: 'hardware_serial_no' },
    { text: 'Hardware IMEI', value: 'hardware_imei' },
    { text: 'Hardware Status', value: 'hardware_status' },
    { text: 'Hardware Position', value: 'hardware_position' },
    { text: 'Hardware Remarks', value: 'hardware_remarks' },
    { text: 'Sim Position', value: 'sim_position' },
    { text: 'Sim Remarks', value: 'sim_remarks' },
    { text: 'Modified At', value: 'modifiedAt' },
    { text: 'Deleted At', value: 'deletedAt' },
]

export default function History(props) {

  const classes = useStyles();
  const [state, setState] = React.useState({
      rowsPerPage: 10,
      page: 0,
      matchedSimcards: [],
      simcards: [],
      operators: [],
      sim_types: [],
      selectedSim: {},
      table: {
        actual_headers: {},
        client: '',
        id: ''
      },
      selectedSims: {},
      totalHistoryCount: 0
  })


  const handleChangePage = (event, newPage) => {
    setState(state => ({
      ...state,
      page: newPage
    }))
  };


  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
      setOpen(true);
  };

  const handleClose = () => {
      setOpen(false);
  };

  const clearAllHeaders = () => {
    let headers = {...state.table.actual_headers}
    Object.keys(headers).forEach(key => {
        headers[key] = false
    })
    setState(state => ({
        ...state,
        table: {
            ...state.table,
            actual_headers: headers
        }
    }))
  }

  const selectAllHeaders = () => {
    let headers = {...state.table.actual_headers}
    Object.keys(headers).forEach(key => {
        headers[key] = true
    })
    setState(state => ({
        ...state,
        table: {
            ...state.table,
            actual_headers: headers
        }
    }))
  }

  const handleChangeRowsPerPage = event => {
    let value = +event.target.value
    setState(state => ({
      ...state,
      page: 0,
      rowsPerPage: value
    }))
  };

  React.useEffect(() => {
    let headers = {};
    headers['S No.'] = true;
    // headers['Actions'] = true;
    history_header.forEach(header => {
        headers[header.text] = true;
    })
    setState(state => ({
        ...state,
        table: {
            ...state.table,
            actual_headers: headers
        }
    }))
  }, [])

  const changeHandler = (e, key, prop) => {
    let value = e.target.value;
    if (key) {
        setState(state => ({
            ...state,
            [key]: {
                ...state[key],
                [prop]: value
            }
        }))
    } else {
        setState(state => ({
            ...state,
            [prop]: value
        }))
    }
  }

  React.useEffect(() => {
      let searchKey = "";
      let searchValue = "";
      if (/\$$/.test(props.search || "") && props.search.includes("=")) {
        searchKey = props.search.split("=")[0];
        searchValue = props.search.split("=")[1].split("$")[0];
      } else if (props.search.length) return;
        axios.get(
            `https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/history?limit=${state.rowsPerPage}&offset=${state.page * state.rowsPerPage}&client=${state.table.client}&hardware_no=${state.table.id}&searchKey=${searchKey}&searchValue=${searchValue}`
        ).then(
          (res) => {
              let dev = res.data.results;
              setState(state => ({
                ...state,
                simcards: dev,
                matchedSimcards: dev,
                clients: res.data.allClients,
                ids: res.data.allDevices,
                totalHistoryCount: res.data.total,
              }));
          }

      ).catch(
          err => {
              alert('Error Occured. See console.')
              console.log(err);
          }
      )
  }, [state.rowsPerPage, state.page, state.table.client, state.table.id, props.search]);

  const edit = () => {
    let resp = window.confirm("Are you sure?");
        if (resp) {
            let url = 'https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/edit-sim';
            Axios.post(url, JSON.stringify(state.selectedSim)).then(
                (res) => {
                console.log(res);
                if (res.status === 200) {
                    alert('device edited');
                }
                }
            ).catch(
                err => {
                alert('Error Occured, check console for more');
                }
            );
        }
  }

//   const toggleSim = (sim) => {
//       let selected = {...state.selectedSims};
//       if (selected[sim._id]) {
//           delete selected[sim._id];
//           console.log("delete", selected);
//       } else {
//           selected[sim._id] = sim;
//           console.log("add", selected);
//       }
//       setState(state => ({
//           ...state,
//           selectedSims: selected
//       }))
//   }

  return (
    <Grid container style={{padding: '20px'}}>
      <Grid item xs={12}>
        <Grid container justify="space-between">
          <Grid item>
              <Button onClick={handleClickOpen} variant="outlined">Set Display Items</Button>
          </Grid>
          <Grid item>
              <TextField style={{ width: '150px'}} variant="outlined" select label="Clients" value={state.table.client} onChange={e => changeHandler(e, 'table', 'client')}>
                  <MenuItem value={''}>All</MenuItem>
                  {(state.clients || []).map(operator => (<MenuItem key={operator} value={operator}>{operator}</MenuItem>))}
              </TextField>
              <TextField style={{ width: '150px'}} variant="outlined" select label="Device Id" value={state.table.id} onChange={e => changeHandler(e, 'table', 'id')}>
                  <MenuItem value={''}>All</MenuItem>
                  {(state.ids || []).map(type => (<MenuItem key={type} value={type}>{type}</MenuItem>))}
              </TextField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
            <TableHead>
            <TableRow>
              {state.table.actual_headers['S No.'] ? <TableCell align="center">S.No.</TableCell> : null}
              {history_header.map(header => state.table.actual_headers[header.text] ? (<TableCell key={header.text} align="center">{header.text} ({header.value})</TableCell>) : null)}
            </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(state.matchedSimcards) ? [...state.matchedSimcards].map((device, index) => {
                  return (<TableRow hover key={index}>
                    {state.table.actual_headers['S No.'] ? <TableCell align="center">{index+1}</TableCell>: null}
                    {history_header.map(header => state.table.actual_headers[header.text] ? 
                        (<TableCell key={header.text} 
                            // onClick={() => toggleSim(device)} 
                            style={{
                            backgroundColor: state.selectedSims[device._id] ? '#3F51B5' : 'inherit',
                            color: state.selectedSims[device._id] ? 'white' : 'inherit',
                        }} align="center">
                            <Tooltip title={device.newValue ? device.newValue[header.value] : 'Deleted'}>
                                <span style={{
                                    color: device.newValue ? (device.newValue[header.value] !== device[header.value]? 'blue' : 'black') : 'blue'
                                }}>{device[header.value] || 'NA'}</span>
                            </Tooltip>
                            </TableCell>) 
                        : null)}
                  </TableRow>)
              }) : null}
            </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={state.totalHistoryCount}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      </Grid>
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">{"Set Display Item"}</DialogTitle>
          <DialogContent>
          <Grid container>
          {Object.keys(state.table.actual_headers).map(key => (
          <Grid item xs={6} key={key}>
              <FormControlLabel
              control={
                  <Checkbox
                  checked={state.table.actual_headers[key]}
                  onChange={() => {
                      setState(state => ({
                          ...state,
                          table: {
                              ...state.table,
                              actual_headers: {
                                  ...state.table.actual_headers,
                                  [key]: !state.table.actual_headers[key]
                              }
                          }
                      }))
                  }}
                  name="checkedB"
                  color="primary"
                  />
              }
              label={key}
              />
          </Grid>
          ))}            
          </Grid>
          </DialogContent>
          <DialogActions>
          <Button onClick={clearAllHeaders} color="primary" autoFocus>
              Clear All
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
              Ok
          </Button>
          <Button onClick={selectAllHeaders} color="primary" autoFocus>
              Select All
          </Button>
          </DialogActions>
      </Dialog>
      <div className="modal fade" id="EditSim" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
              <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Edit Sim</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div className="modal-body">
                  <Grid container>
                  {Object.keys(state.selectedSim).map(key => {
                      if (key === "_id" || key === "createdAt") {
                          return null;
                      } else {
                          return (
                              <Grid item xs={6} key={key}>
                              {key.replace(/_/g, ' ').toLocaleUpperCase() + ": "}
                              <input type="text" value={state.selectedSim[key]} onChange={(e) => changeHandler(e, 'selectedSim', key)}/>
                              </Grid>
                          )
                      }
                  })}
                  </Grid>
              </div>
              <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                  <button type="button" className="btn btn-primary" onClick={edit}>Edit</button>
              </div>
              </div>
          </div>
      </div>
    </Grid>
  );
}