import React, { useState, useEffect } from "react";
import "./DeviceList.css";
import Axios from "axios";
import Combobox from "react-widgets/lib/Combobox";
import "react-widgets/dist/css/react-widgets.css";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import Grid from "@material-ui/core/Grid";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {
  TablePagination,
  Tooltip,
  TextField,
  MenuItem,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import xlsx from "xlsx";
import moment from "moment";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ReactJson from "react-json-view";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import usePagination from "../../hooks/usePagination";
import CircularProgress from "@material-ui/core/CircularProgress";

const deviceMsgs = [
  "STATUS#",
  "MODE#",
  "PARAM#",
  "VERSION#",
  "SERVER#",
  "MODE,1,60,180#",
  "HBT#",
  "HBT,3#",
  "SERVER,0,13.232.89.32,1337#",
  "TIMER,60,60#",
  "RESET#",
  "FACTORY#",
];
const email = new URLSearchParams(window.location.search).get("email");
const deleteButtonAccess = [
  "niranjan@intugine.com",
  "amrita@intugine.com",
  "lovepreet@intugine.com",
  "lakhandhanani@intugine.com",
  "akashacharya@intugine.com",
  "akhil@intugine.com"
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    maxHeight: "677px",
  },
});

const device_header = [
  { text: "Client", value: "client", defaultShow: true },
  { text: "City", value: "city", defaultShow: true },
  { text: "Device Transaction", value: "transaction", defaultShow: false },
  { text: "Added by", value: "added_by", defaultShow: false },
  { text: "Hardware Vendor", value: "vendor", defaultShow: true },
  { text: "Hardware Type", value: "hardware_type", defaultShow: false },
  { text: "Hardware Model", value: "hardware_model", defaultShow: false },
  { text: "Parsed By", value: "parsed_by", defaultShow: false },
  { text: "Hardware No.", value: "hardware_no", defaultShow: true },
  { text: "Hardware Sl.No", value: "hardware_serial_no", defaultShow: true },
  { text: "Hardware IMEI", value: "hardware_imei", defaultShow: true },
  { text: "Hardware Status", value: "hardware_status", defaultShow: false },
  { text: "Hardware Position", value: "hardware_position", defaultShow: true },
  { text: "Hardware Remarks", value: "hardware_remarks", defaultShow: false },
  { text: "Sim Type", value: "sim_type", defaultShow: true },
  { text: "Sim Mobile No.", value: "sim_mobile_no", defaultShow: true },
  { text: "Sim Serial No.", value: "sim_serial_no", defaultShow: true },
  { text: "Sim Code", value: "sim_code", defaultShow: false },
  { text: "Sim Status", value: "sim_status", defaultShow: true },
  { text: "Sim Position", value: "sim_position", defaultShow: true },
  { text: "Sim Remarks", value: "sim_remarks", defaultShow: false },
  { text: "BT App Password", value: "bt_app_pwd", defaultShow: true },
  { text: "Ownership", value: "device_ownership", defaultShow: true },
];

function DeviceList(props) {
  const classes = useStyles();
  const [devices, setDevices] = useState([]);
  const [config, setConfig] = useState({
    imei: "5",
    tel: "",
    msg: "",
    sim: "1",
  });
  // const [resp, setResp] = useState([])
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedDevicesTel, setSelectedDevicesTel] = useState([]);
  const [selectedDevicesName, setSelectedDevicesName] = useState([]);
  const [selectedDevicesAction, setSelectedDevicesAction] = useState("delete");
  const [deleteForm,setDeleteForm] = useState({})
  const [state, setState] = useState({
    rowsPerPage: 10,
    page: 0,
    msgDialog: false,
    msgTab: "one",
    msgTabDevices: "",
    msgTabMsg: "",
    msgTabHistory: {},
    table: {
      selectedClient: "",
      clients: [],
      actual_headers: {},
    },
  });
  const [open, setOpen] = React.useState(false);
  const [reload, reloadNow] = React.useState(true);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
   if(props.clientList?.length){
      setState((state) => ({
        ...state,
        table: {
          ...state.table,
          clients: [...props.clientList],
        
      }}));
    }
  
  },[props.clientList])

  const advanceChangeHandler = (keys, values) => {
    setState((state) => {
      const newState = { ...state };
      keys.forEach((k, i) => {
        newState[k] = values[i];
      });
      return newState;
    });
  };

  const sendMsgToDevices = () => {
    if (state.msgTabDevices && state.msgTabMsg) {
      Axios.post(
        "https://aq2n88o9ll.execute-api.ap-south-1.amazonaws.com/latest/concox/send_ota_command",
        { devices: state.msgTabDevices.split(","), message: state.msgTabMsg }
      )
        .then((r) => {
          console.log(r);
          toast.success("Message Sent");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error");
        });
    }
  };

  const handleDevicesFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e) => {
      const workbook = xlsx.read(e.target.result, {
        type: "binary",
      });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const devices = [];
      devices.push(...xlsx.utils.sheet_to_json(worksheet, { raw: false }));
      advanceChangeHandler(
        ["msgTabDevices"],
        [devices.map((d) => d.Devices).join(",")]
      );
    };
  };

  const fetchToDevices = () => {
    if (state.msgTabDevices) {
      const devices = state.msgTabDevices.split(",");
      Promise.all(
        devices.map((d) =>
          Axios.get(
            "https://aq2n88o9ll.execute-api.ap-south-1.amazonaws.com/latest/concox/ota_commands?device=" +
              d
          )
        )
      )
        .then((r) => {
          console.log(r);
          const data = {};
          r.forEach((res, index) => {
            data[devices[index]] = res.data;
          });
          advanceChangeHandler(["msgTabHistory"], [data]);
        })
        .catch((err) => {
          console.log(err);
          advanceChangeHandler(
            ["msgTabHistory"],
            [{ error: "Error occured in 1 of the devices" }]
          );
        });
    }
  };

  const fetchQueueDevices = () => {
    Axios.get(
      "https://aq2n88o9ll.execute-api.ap-south-1.amazonaws.com/latest/concox/ota_commands/current_queue"
    )
      .then((r) => {
        advanceChangeHandler(["msgTabHistory"], [r.data]);
      })
      .catch((err) => {
        console.log(err);
        advanceChangeHandler(["msgTabHistory"], [{ error: "Error occured" }]);
      });
  };

  const { page, pageSize, handlePageChange, handlePageSizeChange } =
    usePagination({});

  let telChangeHandler = (e) => {
    let tele = e.target.value;
    setConfig((config) => {
      return {
        imei: config.imei,
        tel: tele,
        msg: config.msg,
        sim: config.sim,
      };
    });
  };

  let simChangeHandler = (e) => {
    let sim = e.target.value;
    setConfig((congif) => {
      return {
        imei: config.imei,
        tel: config.tel,
        msg: config.msg,
        sim: sim,
      };
    });
  };

  let okHandler = () => {
    let teles = config.tel.split(",");
    Axios.post(
      "https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/config",
      JSON.stringify({
        tels: teles,
        msg: document.getElementById("config_msg_input").value,
        sim: config.sim,
      })
    ).then((res) => {
      console.log(res);
      if (res.status === 200) {
        alert("device configured");
        setSelectedDevices((devices) => []);
        setSelectedDevicesTel((tels) => []);
        setSelectedDevicesName((_) => []);
      }
    });
  };

  let [editDevice, setEditDevice] = useState({
    id: "",
    createdAt: "",
  });
  const [total, setTotal] = useState(0);

  const [autoReload, setAutoReload] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (page !== 1) handlePageChange(1);
    else {
      setAutoReload(!autoReload);
    }
  }, [props.search]);

  useEffect(() => {
    // pageNumber: page,
    // pageSize,
    console.log("render", state.table.selectedClient);
    let unmounted = false;
    let source = Axios.CancelToken.source();
    const params = {
      limit: pageSize,
      page: page,
      search: props.search && props.search != '' ? props.search : ( state.table.selectedClient ?? undefined),
    };
   Axios.get(
      "https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/devices",
      { params, cancelToken: source.token }
    )
      .then((res) => {
        if (!unmounted) {
          let dev = res.data.devices;
          setTotal(res.data?.total);
          // console.log("ye hai dev", dev);
          setDevices((devices) => dev);
          setState((state) => ({
            ...state,
            table: {
              ...state.table,
              // clients: [...new Set(dev.map((device) => device.client))],
            },
          }));
        }
      })
      .catch((err) => {
        alert("Error Occured. See console.");
        console.log(err);
      });

    return () => {
      unmounted = true;
      // source.cancel();
    };
  }, [reload, props.reload, page, pageSize, autoReload]);

  const selectAll = () => {
    let ids = [];
    let tels = [];
    let names = [];
    devices.forEach((d) => {
      ids.push(d._id);
      tels.push(d.sim_mobile_no);
      names.push(d.hardware_no);
    });
    setSelectedDevicesTel((tel) => tels);
    setSelectedDevices((devices) => ids);
    setSelectedDevicesName((_) => names);
  };

  const dselectAll = () => {
    setSelectedDevicesTel((tel) => []);
    setSelectedDevices((devices) => []);
    setSelectedDevicesName((_) => []);
  };

  const toggleDevices = (id, tel, name) => {
    if (selectedDevices.includes(id)) {
      let newDevices = [...selectedDevices];
      let tels = [...selectedDevicesTel];
      let names = [...selectedDevicesName];
      let index = selectedDevices.indexOf(id);
      newDevices.splice(index, 1);
      tels.splice(index, 1);
      names.splice(index, 1);
      setSelectedDevicesTel((tel) => tels);
      setSelectedDevices((devices) => newDevices);
      setSelectedDevicesName((_) => names);
      return;
    }
    let newDevices = [...selectedDevices];
    let tels = [...selectedDevicesTel];
    let names = [...selectedDevicesName];
    newDevices.push(id);
    tels.push(tel);
    names.push(name);
    setSelectedDevicesTel((tel) => tels);
    setSelectedDevices((devices) => newDevices);
    setSelectedDevicesName((_) => names);

  };

  let editHandler = (device) => {
    setEditDevice((config) => ({
      ...device,
      updatedAt: new Date(),
    }));
  };

  let deleteDevices = (device) => {
    let resp = window.confirm("Are you sure?");
    let query = "?email="+email
    // console.log("selectedDevicesAction",selectedDevicesAction)
    if(selectedDevicesAction == 'writeof')
    query += "&writeof=true"
    if (resp) {
      Axios.post(
        "https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/delete-devices"+query,
        // JSON.stringify(selectedDevices)
        { devices: selectedDevices, form: deleteForm}
      )
        .then((res) => {
          console.log(res);
          alert(res.data.status);
          reloadNow((v) => !v);
        })
        .catch((err) => {
          alert("Error Occured, check console for more");
        });
    }
  };

  // let edit = () => {
  //   let resp = window.confirm("Are you sure?");
  //   if (resp) {
  //     let url =
  //       "https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/edit-device";
  //     Axios.post(
  //       url,
  //       JSON.stringify({ ...editDevice, updated_by: email || null })
  //     )
  //       .then((res) => {
  //         console.log(res);
  //         if (res.status === 200) {
  //           alert("device edited");
  //           reloadNow((v) => !v);
  //         }
  //       })
  //       .catch((err) => {
  //         alert("Error Occured, check console for more");
  //       });
  //   }
  // };
  let edit = () => {
    let resp = window.confirm("Are you sure?");
    if (resp) {
      let url = "https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/add-new-devices?email="+email+"&editAll=true"
      delete editDevice._id
      Axios.post(
        url,
        { data: [{ ...editDevice, updated_by: email || null }] }
      )
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            alert("device edited");
            reloadNow((v) => !v);
          }
        })
        .catch((err) => {
          let error_msg = err.response?.data?.msg ?? "Error Occured, check console for more"
          let actual_error = ""
          if(err?.response?.data?.result){
            if(err?.response?.data?.result?.sct){
              actual_error = "\n ***** SCT Devices Error *****"
              if(!err?.response?.data?.result?.sct?.client)
              actual_error += "\nInvalid Client Name - "+ err?.response?.data?.result?.sct?.client_list.toString()
              if(!err?.response?.data?.result?.sct?.facility)
              actual_error += "\nInvalid Facility Name - "+ err?.response?.data?.result?.sct?.facility_list.toString()
            }
            if(err?.response?.data?.result?.intracity){
              actual_error += "\n ***** Intracity Devices Error *****"
              if(!err?.response?.data?.result?.intracity?.client && err?.response?.data?.result?.intracity?.client_list?.length)
              actual_error += "\nInvalid Client Name - "+ err?.response?.data?.result?.intracity?.client_list.toString()
              if(!err?.response?.data?.result?.intracity?.facility && err?.response?.data?.result?.intracity?.facility_list?.length)
              actual_error += "\nInvalid Facility Name - "+ err?.response?.data?.result?.intracity?.facility_list.toString()
            }
          }
          alert(error_msg+actual_error);
        });
    }
  };

  React.useEffect(() => {
    let headers = {};
    headers["S No."] = true;
    headers["Date and Time"] = false;
    device_header.forEach((header) => {
      headers[header.text] = header.defaultShow;
    });
    headers["Actions"] = true;
    setState((state) => ({
      ...state,
      table: {
        ...state.table,
        actual_headers: headers,
      },
    }));
  }, []);

  // let viewHandler = (tel) => {
  //     Axios.post('https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/response', JSON.stringify({
  //         tel: 9711325880
  //     })).then(
  //         res => {
  //             setResp(resp => res.data);
  //         }
  //     ).catch(
  //         err => {
  //             alert('Some Error Occured. Check Console');
  //             console.log(err);
  //         }
  //     );
  // }
  const handleChangePage = (event, newPage) => {
    setState((state) => ({
      ...state,
      page: newPage,
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    let value = +event.target.value;
    setState((state) => ({
      ...state,
      page: 0,
      rowsPerPage: value,
    }));
  };

  const clearAllHeaders = () => {
    let headers = { ...state.table.actual_headers };
    Object.keys(headers).forEach((key) => {
      headers[key] = false;
    });
    setState((state) => ({
      ...state,
      table: {
        ...state.table,
        actual_headers: headers,
      },
    }));
  };

  const selectAllHeaders = () => {
    let headers = { ...state.table.actual_headers };
    Object.keys(headers).forEach((key) => {
      headers[key] = true;
    });
    setState((state) => ({
      ...state,
      table: {
        ...state.table,
        actual_headers: headers,
      },
    }));
  };
  const onChangeDelete = (e, prop) => {
    let value = e.target.value;
    setDeleteForm((device) => ({
      ...device,
      [prop]: value,
    }));
    console.log("remark set", deleteForm)
  };
  const onChangeEdit = (e, prop) => {
    let value = e.target.value;
    setEditDevice((device) => ({
      ...device,
      [prop]: value,
    }));
  };

  const changeHandler = (e, key, prop) => {
    console.log(e);
    let value = e.target.value;
    if (key) {
      setState((state) => ({
        ...state,
        [key]: {
          ...state[key],
          [prop]: value,
        },
      }));
    } else {
      setState((state) => ({
        ...state,
        [prop]: value,
      }));
    }
    if(prop == "selectedClient"){
      reloadNow((v) => !v);
    }
  };
 const setDeleteAction = (e)=>{
  // console.log("setDeleteAction", e.target.value)
    setSelectedDevicesAction(e.target.value)
 }
  const deleteDevice = (e, device) => {
    let resp = window.confirm("Are you sure?");
    e.stopPropagation();
    if (resp) {
      console.log(device._id);
      let url =
        "https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/delete-device";
      Axios.post(
        url,
        JSON.stringify({
          _id: device._id,
          submitted_by: email || null,
        })
      )
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            alert("device deleted");
            reloadNow((v) => !v);
          }
        })
        .catch((err) => {
          alert("Error Occured, check console for more");
        });
    }
  };

  const download = () => {
    const params = {
      limit: total,
      page: 1,
      search: props.search ?? undefined,
    };
    setLoading(true);

    Axios.get(
      "https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/devices",
      { params }
    )
      .then((res) => {
        let dev = res.data.devices;
        console.log("ye hai dev", dev);
        return dev;
      })
      .then((devices) => {
        const data = [...devices]

          .filter(
            (obj) =>
              obj.client === state.table.selectedClient ||
              state.table.selectedClient === ""
          )
          .filter(
            (obj) =>
              selectedDevices.length <= 0 || selectedDevices.includes(obj._id)
          )
          .map((row) => {
            let obj = {};
            device_header.forEach((head) => {
              obj[head.text] = row[head.value];
            });
            obj["Date and Time"] = row["date"]
              ? moment(new Date(row["date"])).format("YYYY-MM-DD")
              : "";
            obj["_id"] = row["_id"];
            return obj;
          });
        const wb = xlsx.utils.book_new();
        const file_name = `devices.xlsx`;
        const sheet_name = "data";
        xlsx.utils.book_append_sheet(
          wb,
          xlsx.utils.json_to_sheet(data),
          sheet_name
        );
        xlsx.writeFile(wb, file_name);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Grid container style={{ padding: "20px" }}>
      <Grid item xs={12}>
        <Grid
          container
          justify="space-between"
          style={{ alignItems: "center" }}
        >
          <Grid item>
            <Button onClick={handleClickOpen} variant="outlined">
              Set Display Items
            </Button>
            <Button onClick={download} variant="outlined" disabled={loading}>
              Download
            </Button>
            <Button
              onClick={() =>
                advanceChangeHandler(
                  ["msgDialog", "msgTabDevices"],
                  [true, selectedDevicesName.join(",")]
                )
              }
              variant="outlined"
            >
              Send Msgs
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={selectAll} variant="outlined">
              Select All
            </Button>
            <Button onClick={dselectAll} variant="outlined">
              Deselect All
            </Button>
            <TextField
              style={{ width: "150px" }}
              variant="outlined"
              select
              label="Client"
              value={state.table.selectedClient}
              onChange={(e) => changeHandler(e, "table", "selectedClient")}
            >
              <MenuItem value={""}>All</MenuItem>
              {(state.table.clients || []).map((client) => (
                <MenuItem key={client} value={client}>
                  {client}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ position: "relative" }}>
        {loading && (
          <CircularProgress
            style={{
              position: "absolute",
              zIndex: 999,
              left: "50%",
              top: "20%",
            }}
          />
        )}

        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table
            className={classes.table}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {state.table.actual_headers["S No."] ? (
                  <TableCell align="center">S.No.</TableCell>
                ) : null}
                {device_header.map((header) =>
                  state.table.actual_headers[header.text] ? (
                    <TableCell key={header.text} align="center">
                      {header.text}
                    </TableCell>
                  ) : null
                )}
                {state.table.actual_headers["Date and Time"] ? (
                  <TableCell align="center">Date</TableCell>
                ) : null}
                {state.table.actual_headers["Actions"] ? (
                  <TableCell align="center">Actions</TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(devices)
                ? [...devices]
                    // .filter((obj) =>
                    //   Object.keys(obj).some((val) =>
                    //     (obj[val] + "")
                    //       .toLowerCase()
                    //       .includes(props.search.toLowerCase())
                    //   )
                    // )
                    .filter(
                      (obj) =>
                        obj.client === state.table.selectedClient ||
                        state.table.selectedClient === ""
                    )
                    // .splice(state.rowsPerPage * state.page, state.rowsPerPage)
                    .map((device, index) => {
                      return (
                        <TableRow
                          hover
                          key={index}
                          onClick={() =>
                            toggleDevices(
                              device._id,
                              device.sim_mobile_no,
                              device.hardware_no
                            )
                          }
                        >
                          {state.table.actual_headers["S No."] ? (
                            <TableCell align="center">{index + 1}</TableCell>
                          ) : null}
                          {device_header.map((header) =>
                            state.table.actual_headers[header.text] ? (
                              <TableCell
                                style={
                                  selectedDevices.includes(device._id)
                                    ? {
                                        "background-color": "#3F51B5",
                                        color: "white",
                                      }
                                    : {}
                                }
                                key={header.text}
                                align="center"
                              >
                                {device[header.value]}
                              </TableCell>
                            ) : null
                          )}
                          {state.table.actual_headers["Date and Time"] ? (
                            <TableCell
                              align="center"
                              style={
                                selectedDevices.includes(device._id)
                                  ? {
                                      "background-color": "#3F51B5",
                                      color: "white",
                                    }
                                  : {}
                              }
                            >
                              {device.date && device.date.split("T").length
                                ? device.date.split("T")[0]
                                : ""}
                            </TableCell>
                          ) : null}
                          {state.table.actual_headers["Actions"] ? (
                            <TableCell align="center">
                              <ButtonGroup
                                variant="text"
                                color="primary"
                                aria-label="text primary button group"
                              >
                                <Tooltip title="Edit this device">
                                  <EditIcon
                                    data-toggle="modal"
                                    data-target="#EditDevice"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      editHandler(device);
                                    }}
                                  />
                                </Tooltip>
                                {/* Commented both delete buttons -- @lovepreet */}
                                {/* {deleteButtonAccess.includes(email) && (
                                  <Tooltip title="Delete this device">
                                    <DeleteIcon
                                      data-toggle="modal"
                                      onClick={(e) => deleteDevice(e, device)}
                                    />
                                  </Tooltip>
                                )} */}
                                {deleteButtonAccess.includes(email) && (
                                  <Tooltip
                                    title="Delete selected devices"
                                    data-toggle="modal"
                                    data-target="#DeleteDeviceDialog"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // deleteDevices(device);
                                    }}
                                  >
                                    <DeleteSweepIcon />
                                  </Tooltip>
                                )}

                                {/* commented

                              {/* <Tooltip title="Configure selected devices">
                                    <SmsIcon onClick={(e) =>{
                                        e.stopPropagation();
                                        configHandler(device)
                                    }} data-toggle="modal" data-target="#configure" />
                                </Tooltip>
                                <Tooltip title="Check response">
                                    <ReplyIcon data-toggle="modal" data-target="#ViewResponse" disabled={device.tel ? false : true} onClick={(e) => {
                                        e.stopPropagation();
                                        viewHandler(device.tel)
                                    }} />
                                </Tooltip> */}
                              </ButtonGroup>
                            </TableCell>
                          ) : null}
                        </TableRow>
                      );
                    })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        {/* page,
            pageSize,
            count: Math.ceil(tableRows?.total / pageSize),
            total: tableRows?.total,
            onPageChange: handlePageChange,
            onPageSizeChange: (e: any) => handlePageSizeChange(e.target.value), */}
        <TablePagination
          component="div"
          page={page - 1}
          total={total}
          count={total}
          rowsPerPage={pageSize}
          onChangeRowsPerPage={(e) => handlePageSizeChange(e.target.value)}
          onChangePage={(e, page) => handlePageChange(page + 1)}
        />
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Set Display Item"}</DialogTitle>
        <DialogContent>
          <Grid container>
            {Object.keys(state.table.actual_headers).map((key) => (
              <Grid item xs={6} key={key}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.table.actual_headers[key]}
                      onChange={() => {
                        setState((state) => ({
                          ...state,
                          table: {
                            ...state.table,
                            actual_headers: {
                              ...state.table.actual_headers,
                              [key]: !state.table.actual_headers[key],
                            },
                          },
                        }));
                      }}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={key}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={clearAllHeaders} color="primary" autoFocus>
            Clear All
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
          <Button onClick={selectAllHeaders} color="primary" autoFocus>
            Select All
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={state.msgDialog}
        onClose={(_) => advanceChangeHandler(["msgDialog"], [false])}
        maxWidth="md"
        fullWidth
      >
        <Grid container justify="center">
          <Grid item>
            <Tabs
              value={state.msgTab}
              onChange={(e, v) => advanceChangeHandler(["msgTab"], [v])}
            >
              <Tab value="one" label="Send Msg" wrapped />
              <Tab value="two" label="History" />
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={state.msgTab} index="one">
              <Grid container justify="space-evenly">
                <Grid item xs={11} style={{ margin: "10px 0" }}>
                  <input type="file" onChange={handleDevicesFile} />
                </Grid>
                <Grid item xs={11} style={{ margin: "10px 0" }}>
                  <TextField
                    label="Devices"
                    variant="outlined"
                    value={state.msgTabDevices}
                    fullWidth
                    onChange={(e) =>
                      advanceChangeHandler(["msgTabDevices"], [e.target.value])
                    }
                  />
                </Grid>
                <Grid item xs={11} style={{ margin: "10px 0" }}>
                  <Autocomplete
                    options={deviceMsgs}
                    freeSolo
                    onChange={(event, newValue) => {
                      advanceChangeHandler(["msgTabMsg"], [newValue]);
                    }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Message"
                        variant="outlined"
                      />
                    )}
                  />
                  {/* <TextField label="Message" variant="outlined" fullWidth onChange={(e) => advanceChangeHandler(["msgTabMsg"], [e.target.value])}/> */}
                </Grid>
                <Grid item xs={12} style={{ margin: "10px 30px" }}>
                  <Button
                    variant="contained"
                    onClick={sendMsgToDevices}
                    disabled={!(state.msgTabDevices && state.msgTabMsg)}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={state.msgTab} index="two">
              <Grid container justify="space-evenly">
                <Grid item xs={11} style={{ margin: "10px 0" }}>
                  <input type="file" onChange={handleDevicesFile} />
                </Grid>
                <Grid item xs={11} style={{ margin: "10px 0" }}>
                  <TextField
                    label="Devices"
                    value={state.msgTabDevices}
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      advanceChangeHandler(["msgTabDevices"], [e.target.value])
                    }
                  />
                </Grid>
                <Grid item xs={11} style={{ margin: "10px 0px" }}>
                  <Button
                    variant="contained"
                    onClick={fetchToDevices}
                    disabled={!state.msgTabDevices}
                  >
                    Get
                  </Button>
                  <Button variant="contained" onClick={fetchQueueDevices}>
                    Queue
                  </Button>
                  <Button
                    variant="contained"
                    onClick={(e) =>
                      advanceChangeHandler(["msgTabHistory"], [{}])
                    }
                  >
                    Clear
                  </Button>
                </Grid>
                <Grid item xs={11} style={{ margin: "10px 0px" }}>
                  <ReactJson
                    src={state.msgTabHistory}
                    style={{ width: "100%", overflowX: "auto" }}
                    theme="monokai"
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </Grid>
          {/* <Grid item xs={12}>
                        <TextField variant="outlined" label="Devices" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField variant="outlined" label="Message" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField variant="outlined" label="Send" />
                    </Grid> */}
        </Grid>
      </Dialog>
      <div
        className="modal fade"
        id="configure"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Configuring device {config.imei}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body modal-body-config">
              <div>
                enter tel:{" "}
                <input
                  type="text"
                  value={config.tel}
                  onChange={telChangeHandler}
                />
              </div>
              <div className="textarea-div">
                enter msg:
                <Combobox
                  data={[
                    "SERVER,0,13.232.89.32,1337,0#",
                    "MODE,1,60,180#",
                    "HBT,3#",
                    "GPSDUP,OFF#",
                    "SENDS,0#",
                    "SF,0FF#",
                    "GPSDUP#",
                    "PARAM#",
                    "HBT#",
                    "MODE#",
                    "SERVER#",
                  ]}
                  id="config_msg"
                  suggest={true}
                />
              </div>
              <div className="textarea-div">
                enter sim number:{" "}
                <input
                  type="text"
                  value={config.sim}
                  onChange={simChangeHandler}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={okHandler}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="EditDevice"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit device
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Grid container justify="space-evenly">
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Client"
                    value={editDevice.client}
                    onChange={(e) => onChangeEdit(e, "client")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="City"
                    value={editDevice.city}
                    onChange={(e) => onChangeEdit(e, "city")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Transaction"
                    value={editDevice.transaction || ""}
                    onChange={(e) => onChangeEdit(e, "transaction")}
                    select
                  >
                    <MenuItem value={""}>Blank</MenuItem>
                    {[
                      ...new Set([
                        "Received From Vendor",
                        "Sent to Client",
                        "Received From Client",
                        editDevice.transaction,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Date"
                    value={editDevice.date}
                    onChange={(e) => onChangeEdit(e, "date")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Added by"
                    value={editDevice.added_by}
                    onChange={(e) => onChangeEdit(e, "added_by")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Hardware Vendor"
                    value={editDevice.vendor || ""}
                    onChange={(e) => onChangeEdit(e, "vendor")}
                    select
                  >
                    <MenuItem value={""}>Blank</MenuItem>
                    {[
                      ...new Set([
                        "Concox","I-triangle","Atlanta","Teltonika","Intugine","Spectrum","Other","Lumax","WanWay","IMZ",
                        editDevice.vendor,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Hardware Type"
                    value={editDevice.hardware_type || ""}
                    onChange={(e) => onChangeEdit(e, "hardware_type")}
                    select
                  >
                    <MenuItem value={""}>Blank</MenuItem>
                    {[
                      ...new Set([
                        "GPS Device",
                        "GSM Device",
                        "Use and Throw",
                        "OBD",
                        "Other",
                        "GPS Lock",
                        "GPS Device 6k",
                        "GPS Device 10k",
                        editDevice.hardware_type,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {/* code */}
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Dashboard Type"
                    value={editDevice.dashboard_type || ""}
                    onChange={(e) => onChangeEdit(e, "dashboard_type")}
                    select
                  >
                    <MenuItem value="">Select Type</MenuItem>
                    {[
                      ...new Set([
                        "SCT",
                        "Intracity",
                        editDevice.dashboard_type,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {/* code */}
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Hardware Model"
                    value={editDevice.hardware_model || ""}
                    onChange={(e) => onChangeEdit(e, "hardware_model")}
                    select
                  >
                    <MenuItem value={""}>Blank</MenuItem>
                    {[
                      ...new Set([
                        "AT4","Spectrum","TS101","Other","Intugine","G-300","S20","TrackLock 700","RNB","G-310P","G-310N","G-310","TT808","UNT",
                        editDevice.hardware_model,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Parsed By"
                    value={editDevice.parsed_by || ""}
                    onChange={(e) => onChangeEdit(e, "parsed_by")}
                    select
                  >
                    <MenuItem value={""}>Blank</MenuItem>
                    {[
                      ...new Set([
                        "Intugine",
                        "Zeliot",
                        "Lumax",
                        editDevice.parsed_by,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    disabled
                    label="Hardware No."
                    value={editDevice.hardware_no}
                    onChange={(e) => onChangeEdit(e, "hardware_no")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Hardware Serial No."
                    value={editDevice.hardware_serial_no}
                    onChange={(e) => onChangeEdit(e, "hardware_serial_no")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    disabled
                    label="Hardware IMEI"
                    value={editDevice.hardware_imei}
                    onChange={(e) => onChangeEdit(e, "hardware_imei")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Hardware Status"
                    value={editDevice.hardware_status || ""}
                    onChange={(e) => onChangeEdit(e, "hardware_status")}
                    select
                  >
                    <MenuItem value={""}>Blank</MenuItem>
                    {[
                      ...new Set([
                        "Active",
                        "Inactive",
                        "Lost",
                        editDevice.hardware_status,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Hardware Position"
                    value={editDevice.hardware_position || ""}
                    onChange={(e) => onChangeEdit(e, "hardware_position")}
                    select
                  >
                    <MenuItem value={""}>Blank</MenuItem>
                    {[
                      ...new Set([
                        "With Client",
                        "With Intugine",
                        "Lost",
                        editDevice.hardware_position,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Hardware Remarks"
                    value={editDevice.hardware_remarks}
                    onChange={(e) => onChangeEdit(e, "hardware_remarks")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Sim Type"
                    value={editDevice.sim_type || ""}
                    onChange={(e) => onChangeEdit(e, "sim_type")}
                    select
                  >
                    <MenuItem value={""}>Blank</MenuItem>
                    {[
                      ...new Set([
                        "Airtel 10 m2m",
                        "Airtel 13 m2m",
                        "Airtel Prepaid",
                        "Voda 10 m2m",
                        "Voda 13 m2m",
                        "Voda Prepaid",
                        "Idea 13 m2m",
                        "Idea 10 m2m",
                        "Idea Prepaid",
                        editDevice.sim_type,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Sim Serial No."
                    value={editDevice.sim_serial_no}
                    onChange={(e) => onChangeEdit(e, "sim_serial_no")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Sim Mobile No."
                    value={editDevice.sim_mobile_no}
                    onChange={(e) => onChangeEdit(e, "sim_mobile_no")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Sim Code"
                    value={editDevice.sim_code}
                    onChange={(e) => onChangeEdit(e, "sim_code")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Sim Status"
                    value={editDevice.sim_status || ""}
                    onChange={(e) => onChangeEdit(e, "sim_status")}
                    select
                  >
                    <MenuItem value={""}>Blank</MenuItem>
                    {[
                      ...new Set([
                        "Active",
                        "Inactive",
                        "Lost",
                        "No Record",
                        editDevice.sim_status,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Sim Position"
                    value={editDevice.sim_position || ""}
                    onChange={(e) => onChangeEdit(e, "sim_position")}
                    select
                  >
                    <MenuItem value={""}>Blank</MenuItem>
                    {[
                      ...new Set([
                        "In Device",
                        "With Client",
                        "With Intugine",
                        "Lost",
                        editDevice.sim_position,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Sim Remarks"
                    value={editDevice.sim_remarks}
                    onChange={(e) => onChangeEdit(e, "sim_remarks")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="BT App Password"
                    value={editDevice.bt_app_pwd}
                    onChange={(e) => onChangeEdit(e, "bt_app_pwd")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Ownership"
                    value={editDevice.device_ownership || ""}
                    onChange={(e) => onChangeEdit(e, "device_ownership")}
                    select
                  >
                    <MenuItem value={""}>Blank</MenuItem>
                    {[
                      ...new Set([
                        "Owned by Client",
                        "Leased",
                        editDevice.device_ownership,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className="btn btn-primary" onClick={edit}>
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="DeleteDeviceDialog"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteDeviceModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteDeviceModalLabel">
                Delete devices
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Grid container justify="space-evenly">
                <Grid item xs={12} style={{padding: "10px",border: "1px solid #ccc",  "borderRadius": "12px"}}>
                <h6> Take Action</h6>
                <form >
                <div class="form-check-inline" onChange={setDeleteAction}>
      <label class="form-check-label" for="radio1">
        <input type="radio" class="form-check-input" id="radio1"  name="optradio" value="delete"  />Delete
      </label>
    </div>
    <div class="form-check-inline" onChange={setDeleteAction}>
      <label class="form-check-label" for="radio2">
        <input type="radio" class="form-check-input" id="radio2" name="optradio"  value="writeof" />WriteOf
      </label>
    </div>
    <div >
    <TextField style={{ marginBottom: "10px" }}  label="Add Remark" value={deleteForm.remark} onChange={(e) => onChangeDelete(e, "remark")}
                  />
    </div>
    </form>

                </Grid>
                <Grid item xs={12} style={{padding: "10px",border: "1px solid #ccc",  borderRadius: "12px", marginTop: "5px"}}>
                  <h6>Selected Devices</h6>
                  <Grid container justify="space-evenly" style={{"maxHeight": "35vh","overflow": "auto"}}>
                  { 
                 selectedDevicesName.map((device,index)=>
                    ( <Grid item xs={3} key={index}>{index+1}.   { device }</Grid>)
                  ) 
                  }
                  </Grid>
                </Grid>
                
              </Grid>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className="btn btn-primary" onClick={deleteDevices}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Grid>
  );
}

export default DeviceList;
